// Text Fields
.text-field {
    position: relative;

    // One Line Input
    &__input {
        background: rgba(0, 0, 0, 0) !important;
        border: 0;
        border-bottom: 1px solid $text-field-unfocused-color;
        height: $text-field-height;
        width: 100%;
        font-size: 16px;
        font-weight: $text-field-font-weight;
        line-height: $text-field-height;
        color: $text-field-color;
        outline: none;
        margin-top: 12px;
        padding: 0;
        transition: border-bottom $transition-duration ease-in;

        &:focus {
            border-bottom: 1px solid $text-field-accent-color;

            +label {
                color: $text-field-accent-color;
            }
        }

        // Textarea Input
        &--textarea {
            font-family: inherit;
            color: $text-field-placeholder-color;
            height: 100%;

            &:focus {
                color: $text-field-color;
            }

            &.text-field__input--expandable {
                resize: vertical;
            }
        }
    }

    &__label {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: $text-field-unfocused-color;
        transition: all $transition-duration ease-in;
        cursor: text;
    }
}

// Placeholders
::placeholder {
    color: $text-field-placeholder-color;
    font-weight: 300;
}
