// Card
.card {
    position: relative;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    margin: 0 0 $card-margin-bottom;
    padding: 0;
    text-align: left;

    // Image on top of the card
    &__image {
        position: relative;
        overflow: hidden;
        height: $card-image-height;
        border-radius: 2px 2px 0 0;

        // Image on the left side
        &--left {
            position: absolute;
            top: 0;
            left: 0;
            width: $card-image-width;
            height: 100%;
            border-radius: 2px 0 0 2px;

            ~ h3,
            ~ div {
                padding-left: calc(#{$card-image-width} + #{$card-padding});
            }
        }

        // Image on the right side
        &--right {
            position: absolute;
            top: 0;
            right: 0;
            width: $card-image-width;
            height: 100%;
            border-radius: 0 2px 2px 0;

            ~ h3,
            ~ div {
                padding-right: calc(#{$card-image-width} + #{$card-padding});
            }
        }

        // Responsive image
        // On top when viewport width is smaller than breakpoint
        // On the side when viewport width is larger than breakpoint
        @media (max-width: $card-image-breakpoint) {
            &--left#{&}--responsive,
            &--right#{&}--responsive {
                position: relative;
                overflow: hidden;
                height: $card-image-height;
                border-radius: 2px 2px 0 0;
                width: auto;
            }

            &--responsive ~ h3,
            &--responsive ~ div {
                padding-left: $card-padding;
                padding-right: $card-padding;
            }
        }
    }

    &__img {
        min-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__title {
        font-weight: 300;
        font-size: 24px;
        margin: 0;
        padding: $card-padding $card-padding 0;
        text-align: left;
    }

    &__content {
        padding: ($card-padding / 2) $card-padding $card-padding;
    }

    &__actions {
        padding: ($card-padding / 4) $card-padding ($card-padding / 4) ($card-padding - $button-sides-padding);
        border-top: 1px solid rgba(0, 0, 0, .1);
        text-align: left;
    }

    &__button {
        @extend .button;
        @extend .button--flat;
    }
}
