// Global Font Style
html {
    font-family: $font-stack;
    font-weight: 300;
    font-size: $page-font-size;
    line-height: 1.4em;
}

// Headers
.heading {
    &--1 {
        font-size: 4rem;
        font-weight: 300;
        line-height: 4rem;
        margin: 1.2rem 0 .8rem;
    }

    &--2 {
        font-size: 3.4rem;
        font-weight: 300;
        line-height: 3.4rem;
        margin: 1.2rem 0 .8rem;
    }

    &--3 {
        font-size: 2.8rem;
        font-weight: 300;
        line-height: 2.8rem;
        margin: 1.2rem 0 .8rem;
    }

    &--4 {
        font-size: 2.2rem;
        font-weight: 300;
        line-height: 2.2rem;
        margin: 1.2rem 0 .8rem;
    }

    &--5 {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.6rem;
        margin: 1rem 0 .6rem;
    }

    &--6 {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1rem;
        margin: .8rem 0 .4rem;
    }
}

// Styles
.typography {
    // Text styles
    &--strong {
        font-weight: 400;
    }

    &--emphasis {
        font-style: italic;
    }

    &--underline {
        border-bottom: 1px solid rgba(0, 0, 0, .4);
    }

    &--blockquote {
        margin: 0;
        padding-left: $blockquote-margin;
        border-left: 4px solid $blockquote-border-color;
    }

    &--link {
        text-decoration: none;
        border-bottom: 1px dotted currentColor;

        &:hover,
        &:focus {
            border-bottom: 1px solid currentColor;
        }
    }

    // Text align
    &--align-left {
        text-align: left;
    }

    &--align-center {
        text-align: center;
    }

    &--align-right {
        text-align: right;
    }
}
