// Page Background
.page {
    background-color: #f5f5f5;
}

// Page Content
.page__content {
    max-width: $page-content-width;
    margin: 0 auto;
    padding: $page-content-padding-top 8px 8px;
}
