// Grids
.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__col {
        &--1 {
            width: 100%;
        }

        &--m--1 {
            @media only screen and (min-width: $breakpoint-s-m) {
                width: 100%;
            }
        }

        &--l--1 {
            @media only screen and (min-width: $breakpoint-m-l) {
                width: 100%;
            }
        }
    }
}

[class^="grid__col--"],
[class*=" grid__col--"] {
    flex: 0 0;
    flex-basis: auto;
}


// Generating column classes
$i: 2;
@while $i <= $cols-number {
    $j: 1;

    @while $j <= $i {
        @if $j != $i {
            .grid__col--#{$j}-#{$i} {
                $width: 100% * ($j / $i) - $col-margin;

                width: $width;
            }

            .grid__col--m--#{$j}-#{$i} {
                @media only screen and (min-width: $breakpoint-s-m) {
                    $width: 100% * ($j / $i) - $col-margin;

                    width: $width;
                }
            }

            .grid__col--l--#{$j}-#{$i} {
                @media only screen and (min-width: $breakpoint-m-l) {
                    $width: 100% * ($j / $i) - $col-margin;

                    width: $width;
                }
            }
        }

        $j: $j + 1;
    }

    $i: $i + 1;
}
