.visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

// Marks inputs
.grades {
    @at-root .text-field {
        display: inline-block;
        width: 100px;
        margin: 0 20px 0 0;
    }

    &__card {
        transition: all 300ms ease-in-out;
    }

    &__input {
        line-height: 35px;
    }

    &__list {
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;
        font-size: 1.2em;
    }

    &__item {
        display: inline;
        cursor: pointer;
        animation: fadein .5s 1;

        &--fade-in {
            opacity: 1;
        }

        &:before {
            content: ", ";
        }

        &:first-of-type:before {
            display: none;
        }
    }

    &__reset {
        right: 5vmin;
        bottom: 5vmin;
        animation: slideup .5s 1;
    }

    &__output {
        display: block;
        font-size: 4.5em;
        line-height: normal;
    }
}

.weights {
    &__set {
        &--hide {
            display: none;
        }

        & form {
            display: flex;
            justify-content: space-between;

            button {
                flex-basis: 130px;
                margin-top: 12px;
            }
        }

        .text-field {
            flex-basis: calc(100% - 140px);
        }
    }
}

// Other styles
.equation {
    max-width: 100%;
}

.fab {
    padding: 0;
}

.header__title {
    background-image: url(./../images/logo.svg);
    background-repeat: no-repeat;
    background-size: auto 48px;
    background-position: 8px 50%;
    padding-left: 50px !important;
    white-space: nowrap;
}

::selection {
    @extend .text--white;
    @extend .color--indigo;
}


@media only screen and (max-width: $breakpoint-m-l) {
    .header__title {
        flex-basis: 100% !important;
    }

    .nav {
        display: none !important;
    }

    .footer {
        height: 1.5 * $footer-height;
    }
}

// AdSense conatiner
.adsense {
    display: block;
    width: 100%;
    height: 100px;
    margin: 0 0 48px 0;
}

@keyframes fadein {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes slideup {
  0% { bottom: -100px }
  100% { bottom: 5vmin }
}

.google_play_badge {
    width: 150px;
}
