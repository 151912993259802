// Button
.button {
    display: inline-block;
    height: $button-height;
    padding: 0 $button-sides-padding;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    text-decoration: none;
    text-transform: uppercase;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-height;
    vertical-align: middle;
    cursor: pointer;
    transition: all $transition-duration ease-in;

    @include hover {
        opacity: .9;
    }

    &:active {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
        opacity: .8;
    }

    // Flat Button
    &--flat {
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);

        @include hover {
            background-color: rgba(0, 0, 0, .1);
        }

        &:active {
            background-color: rgba(0, 0, 0, .2);
        }

        // Flat Disabled Button
        &.button--disabled {
            background-color: rgba(0, 0, 0, 0);
            color: rgba(0, 0, 0, .26);
        }
    }

    // Disabled Button
    &--disabled {
        background-color: $button-disabled-background;
        color: $button-disabled-color;
        opacity: 1;
        box-shadow: none;
        cursor: default;

        @include hover {
            opacity: 1;
        }

        &:active {
            box-shadow: none;
        }
    }

    // Large Button
    &--large {
        height: 1.3 * $button-height;
        font-size: 1.3 * $button-font-size;
        line-height: 1.3 * $button-height;
    }
}


// Fixed Action Button
.fab {
    @extend .button;
    border-radius: 50%;
    position: fixed;
    right: $fab-position;
    bottom: $fab-position;
    width: $fab-radius;
    height: $fab-radius;
    line-height: $fab-radius;
    text-align: center;
    z-index: 97;

    // Disabled Fixed Action Button
    &--disabled {
        background-color: $button-disabled-background;
        color: $button-disabled-color;
        opacity: 1;
        box-shadow: none;
        cursor: default;

        @include hover {
            opacity: 1;
        }

        &:active {
            box-shadow: none;
        }
    }
}
