// Checkbox
.checkbox {
    display: inline-block;
    position: relative;
    margin: 0 0 10px;
    font-size: 16px;
    line-height: 24px;

    &__input {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        opacity: 0;
        z-index: 0;
    }

    // Unchecked
    &__label {
        display: block;
        padding: 0 0 0 24px;
        cursor: pointer;

        &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            width: 16px;
            height: 16px;
            background-color: transparent;
            border: 2px solid rgba(0, 0, 0 , .54);
            border-radius: 2px;
            z-index: 1;
            transition: all .28s cubic-bezier(.4, 0, .2, 1);
            transition-property: background-color, border-color;
        }

        &:after {
            content: '';
            position: absolute;
            top: 5px;
            left: 5px;
            width: 6px;
            height: 12px;
            border-bottom: 2px solid transparent;
            border-right: 2px solid transparent;
            transform: rotate(45deg);
            z-index: 2;
            transition: border-color .28s cubic-bezier(.4, 0, .2, 1);
        }
    }

    // Checked
    &__input:checked + &__label {
        &:before {
            background-color: $primary-color;
            border-color: $primary-color;
        }

        &:after {
            border-color: #fff;
        }
    }

    // Focused
    &__input:focus + &__label {
        &:before {
            outline: 1px dotted currentColor;
        }
    }
}
